<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<div id="sub" class="sub withdraw">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10241')}}
							</h2>
						</div>
						<div class="inner__wrap">
							<ul class="withdraw-list">
								<li>
									<div class="withdraw__title">
										<span>1.</span>
										{{t('10242')}}
									</div>
									<div class="withdraw__text">
										{{t('10243')}}
									</div>
								</li>
								<li>
									<div class="withdraw__title">
										<span>2.</span>
										{{t('10244')}}
									</div>
									<div class="withdraw__text">
										{{t('10245')}}
									</div>
								</li>
							</ul>
							<div class="withdraw-form">
								<div class="checkbox">
									<label>
										<input type="checkbox" class="chk blind" v-model="check">
										<span class="checkbox__bg"></span>
										<span class="agree__title">{{t('10246')}}</span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<!-- [D] 다음 불가 시 -->
		<div class="btns btns--centered">
			<button 
				type="button" 
				class="btn" 
				:class="{ 'btn-disabled': !check, 'btn-confirm': check}"
				:disabled="!check"
				@click="checkPassword"
				>{{t('10119')}}</button>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore} from "vuex"
import { ref } from "vue"
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup: function(){
		const router = useRouter();
		const store = useStore();

		const password = ref("");
		const showPassword = ref(false);
		const passwordFocus = ref(false);
		const passwordError = ref(false);

		const check = ref(false);

		const goBack = () =>{
			router.go(-1);
		}

		const OnFocusPassword = (isFocus) =>{
			passwordFocus.value = isFocus;
		}

		const OnClickShowPassword = () =>{
			showPassword.value = !showPassword.value;
		}

		const OnClickResetPassword = () =>{
			password.value = "";
		}

		const checkPassword = async () =>{
			router.push("/member/withdraw/step2");
		}
		const { t }= useI18n() //번역필수 모듈

		return {
			goBack,
			checkPassword,
			router,
			store,
			password,
			OnFocusPassword,
			passwordFocus,
			OnClickShowPassword,
			showPassword,
			passwordError,
			OnClickResetPassword,
			check,
			t,
			i18n,
		}
	}
}
</script>